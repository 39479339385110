import React from 'react';
import styles from './Shares.module.css';
import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    FacebookShareButton,
    TelegramIcon,
    TelegramShareButton,
    TwitterIcon,
    TwitterShareButton,
    WhatsappIcon,
    WhatsappShareButton
} from "react-share";

export function Shares() {

    const url = "https://www.professor-puzzler.co.uk";
    const title = "Professor Puzzler's Puzzling Puzzles";
    const description = "Solve the professor's puzzles and raise money for ME Research UK";
    const iconSize = 64;

    return <div className={styles.mainWrapper}>
        <div className={styles.shareButton}>
            <FacebookShareButton url={url} quote={title} >
                <FacebookIcon size={iconSize} round />
            </FacebookShareButton>
        </div>
        <div className={styles.shareButton}>
            <TwitterShareButton url={url} title={title}  >
                <TwitterIcon size={iconSize} round />
            </TwitterShareButton>
        </div>
        <div className={styles.shareButton}>
            <TelegramShareButton url={url} title={title} >
                <TelegramIcon size={iconSize} round />
            </TelegramShareButton>
        </div>
        <div className={styles.shareButton}>
            <WhatsappShareButton url={url} title={title} separator=": " >
                <WhatsappIcon size={iconSize} round />
            </WhatsappShareButton>
        </div>
        <div className={styles.shareButton}>
            <EmailShareButton url={url} subject={title} body={description} >
                <EmailIcon size={iconSize} round />
            </EmailShareButton>
        </div>
    </div>
}