
import React, { useState } from 'react';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import { useHistory } from "react-router-dom";
import styles from './Navigation.module.css';
import SchoolIcon from '@material-ui/icons/School';
import MEResearchUKLogo from '../checkout/images/MERUK-logo.png';
import JustGivingLogo from './just-giving.png';
import { createMuiTheme, Icon, ThemeProvider, SvgIcon, Dialog } from '@material-ui/core';
import { Shares } from '../shares/Shares';
import ShareIcon from '@material-ui/icons/Share';

const theme = createMuiTheme({
    overrides: {
        MuiIcon: {
            root: {
                display: 'contents'
            },
        }
    },
});

const justgivingIcon = <ThemeProvider theme={theme}><Icon>
    <img width="75%" alt='JustGiving' src={JustGivingLogo}></img>
</Icon></ThemeProvider>;

const merukIcon = <ThemeProvider theme={theme}><Icon>
    <img width="75%" alt='ME Research UK' src={MEResearchUKLogo}></img>
</Icon></ThemeProvider>;

const netflixIcon = <ThemeProvider theme={theme}><SvgIcon>
    <svg className={styles.netflixLogo} viewBox="225 0 552 1000"><defs><radialGradient id="9e9e0b21-7ff2-4f98-b7d8-5a186183b3ad-a" r="75%" gradientTransform="matrix(.38 0 .5785 1 .02 0)"><stop offset="60%" stop-opacity=".3"></stop><stop offset="90%" stop-opacity=".05"></stop><stop offset="100%" stop-opacity="0"></stop></radialGradient></defs><path d="M225 0v1000c60-8 138-14 198-17V0H225" fill="#b1060e"></path><path d="M579 0v983c71 3 131 9 198 17V0H579" fill="#b1060e"></path><path d="M225 0v200l198 600V557l151 426c76 3 136 9 203 17V800L579 200v240L423 0H225" fill="url(#9e9e0b21-7ff2-4f98-b7d8-5a186183b3ad-a)"></path><path d="M225 0l349 983c76 3 136 9 203 17L423 0H225" fill="#e50914"></path></svg>
</SvgIcon></ThemeProvider>;

const links = [
    { icon: <SchoolIcon />, name: 'Home', path: '/' },
    { icon: <SchoolIcon />, name: 'Buy Now', path: '/buynow' },
    { icon: <SchoolIcon />, name: 'Login', path: '/login' },
    { icon: <SchoolIcon />, name: 'High Scores', path: '/high-scores' },
    { icon: justgivingIcon, name: 'Donate', externalPath: '//widgets.justgiving.com/Button/Redirect?p=eyJJZCI6IjE2YTA4MzM1LWY2YTgtNDc5Ni05ZDEyLWQ1NzQyNjNiMzQzYiIsIkNoYXJpdHlJZCI6MTgyMjYzLCJTaXplIjoibCIsIlJlZmVyZW5jZSI6InByb2Zlc3NvcnB1enpsZXIiLCJUeXBlIjoiRG9uYXRlIn0=' },
    { icon: merukIcon, name: 'ME Research UK', externalPath: 'https://www.meresearch.org.uk/' },
    { icon: netflixIcon, name: 'Unrest', externalPath: 'https://www.netflix.com/gb/title/80168300' },
    { icon: <SchoolIcon />, name: 'About', path: '/about' },
];

export function Navigation() {

    const [navigationSpeedDialOpen, setNavigationSpeedDialOpen] = React.useState(false);
    const history = useHistory();
    const [shareDialogOpen, setShareDialogOpen] = useState(false);
    

    return <div className={styles.navigationWrapper}>
        <SpeedDial
            ariaLabel="Navigation"
            hidden={false}
            icon={<SpeedDialIcon icon={<SchoolIcon />} />}
            onClose={() => setNavigationSpeedDialOpen(false)}
            onOpen={() => setNavigationSpeedDialOpen(true)}
            open={navigationSpeedDialOpen}
            direction="down"
        >
            {
                links.map(link => <SpeedDialAction
                    key={`link_${link.name}`}
                    tooltipOpen
                    tooltipPlacement="left"
                    tooltipTitle={link.name}
                    icon={link.icon}
                    onClick={() => {
                        if (link.path) {
                            history.push(link.path);
                        }
                        else if (link.externalPath) {
                            window.open(link.externalPath, '_blank');
                        }

                    }}
                />
                )
            }
            <SpeedDialAction
                key={`link_fb`}
                tooltipOpen
                tooltipPlacement="left"
                tooltipTitle="Share"
                icon={<ShareIcon />}
                onClick={() => setShareDialogOpen(true)}
            />

        </SpeedDial>
        <Dialog open={shareDialogOpen} onClose={() => setShareDialogOpen(false)}>
            <Shares></Shares>
        </Dialog>
    </div>;
}