import React from 'react';
import { Navigation } from '../navigation/Navigation';
import styles from './About.module.css';
import Computers from './images/computers.svg';
import Typewriter from './images/typewriter.svg';

export function About() {
    return <><Navigation />
        <div className={styles.backgroundHolder}>
            <div className={styles.backgroundOverlay}></div>
        </div>

        <div className={styles.mainWrapper}>
            <div className={styles.infoPanel}>
                <div className={styles.infoPanelLeft}>
                    <img src={Computers} alt='programmer' height='150vmin' />
                </div>
                <div className={styles.infoPanelRight}>
                    <div className={styles.title}>About</div>
                    <p>
                        The Professor Puzzler game is intended to help raise money for ME Research UK.  It is not
                        affiliated with ME Research UK, just a proud supporter of the charity.  The ME Research
                        UK <a href='https://www.meresearch.org.uk/' target='_blank' rel='noopener noreferrer'>website</a> explains
                        more about the disease and the
                        documentary <a href='https://www.netflix.com/gb/title/80168300' target='_blank' rel='noopener noreferrer'>Unrest</a> gives an
                        insight into the lives of some of those living with ME.
                </p>
                    <p>
                        This is not a professional operation and is maintained as a "hobby project".  There are loads of great online games by professional
                        escape room businesses that are well worth a try.  Some of them helped inspire this project.
                </p>
                </div>
            </div>
            <div className={styles.contactPanel}>
                <div className={styles.contactPanelLeft}>
                    <div className={styles.title}>Contact</div>
                    <p>
                        If you need to contact us then you can email <a href="mailto:help@professor-puzzler.co.uk">help@professor-puzzler.co.uk</a>
                    </p>
                    <p>
                        Please note though, there are no refunds!
                    </p>
                </div>
                <div className={styles.contactPanelRight}>
                    <img src={Typewriter} alt='typewriter' height='150vmin' />
                </div>
            </div>
        </div>
    </>;
}