import React, { useRef } from 'react';
import styles from './Home.module.css';
import Logo from './logo2.svg';
import SwipeableViews from 'react-swipeable-views';
import Preview1 from './images/1.png';
import Preview2 from './images/2.png';
import Preview3 from './images/3.png';
import Preview4 from './images/4.png';
import Preview5 from './images/5.png';
import { autoPlay } from 'react-swipeable-views-utils';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import { Button, Typography } from '@material-ui/core';
import { Parallax, ParallaxLayer } from 'react-spring/renderprops-addons';
import { useSpring, animated } from 'react-spring';
import { useHistory } from 'react-router-dom';
import { Navigation } from '../navigation/Navigation';
import { Legal } from '../legal/Legal';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

export function Home() {
  const [cardImage, setCardImage] = React.useState(0);
  const [step, setStep] = React.useState(0);
  const history = useHistory();

  const cardImageStepChange = (step: number) => {
    setCardImage(step);
  };

  const logoPropsSource = buildLogoTransformProps(step)
  const logoProps = useSpring(logoPropsSource);

  const ref = useRef<Parallax>(null);
  const totalSteps = 5;
  const incrementStep = () => {
    const newStep = (step + 1) % totalSteps;
    setStep(newStep);
    ref.current?.scrollTo(newStep);
  };

  const scale = getScale();
  const initialLogoWidth: number = 500 * scale;


  return <div >
    {
      step > 0 && <><Navigation /><Legal /></>
    }
    <Parallax pages={totalSteps} ref={ref} scrolling={false}>
      <ParallaxLayer offset={0} speed={0.00001}>
        <div className={styles.bookcase} >
        </div>
      </ParallaxLayer>
      <ParallaxLayer offset={0} speed={0.5}>
        <div id='page0' className={`${styles.page} ${styles.page0}`} onClick={incrementStep}>
        </div>
      </ParallaxLayer>
      <ParallaxLayer offset={1} speed={0.5}>
        <div className={`${styles.page} ${styles.page1}`} onClick={incrementStep}>
          <div className={styles.headingWrapper}>
            <div className={styles.infoText}>Have fun, raise money for a wonderful charity and use your brain!</div>
          </div>
        </div>
      </ParallaxLayer>
      <ParallaxLayer offset={2} speed={0.5}>
        <div className={`${styles.page} ${styles.page2}`} onClick={incrementStep}>
          <div className={styles.headingWrapper}>
            <div className={styles.infoText}>This game is provided at the measly price of £1 but we strongly encourage
          you to donate at least £9 to ME Research UK using the link provided on the payment page.</div>
          </div>
        </div>
      </ParallaxLayer>
      <ParallaxLayer offset={3} speed={0.5}>
        <div className={`${styles.page} ${styles.page3}`} onClick={incrementStep}>
          <div className={styles.headingWrapper}>
            <div className={styles.infoText}>The £1 covers the cost of running the site, any money left over after paying the bills will be donated to ME Research UK.</div>
          </div>
        </div>
      </ParallaxLayer>
      <ParallaxLayer offset={4} speed={0.5}>
        <div className={`${styles.page} ${styles.page3}`} onClick={incrementStep}>
          <Card className={styles.gameCard}>
            <CardContent>
              <div className={styles.cardTitle}><Typography variant='h4'>Play Now</Typography></div>
              <div className={styles.cardTop}>
                <div className={styles.caption}>
                  <Typography variant='h5'>Solve the professor's puzzles to claim your prize...</Typography>
                  <Typography variant='h6'>An escape-room style adventure to be played with friends and family</Typography>
                </div>
                <div className={styles.pictureScroller}>
                  <AutoPlaySwipeableViews
                    index={cardImage}
                    interval={2800}
                    onChangeIndex={cardImageStepChange}
                    enableMouseEvents
                  >
                    <div className={styles.previewImage}><img src={Preview1} alt='preview 1' height={250} ></img></div>
                    <div className={styles.previewImage}><img src={Preview2} alt='preview 2' height={250} ></img></div>
                    <div className={styles.previewImage}><img src={Preview3} alt='preview 3' height={250} ></img></div>
                    <div className={styles.previewImage}><img src={Preview4} alt='preview 4' height={250} ></img></div>
                    <div className={styles.previewImage}><img src={Preview5} alt='preview 5' height={250} ></img></div>
                  </AutoPlaySwipeableViews>
                </div>
              </div>
            </CardContent>
            <CardActions>
              <Button variant='outlined' onClick={() => history.push('/buynow')}>Buy Now</Button>
            </CardActions>
          </Card>
        </div>
      </ParallaxLayer>
    </Parallax>
    <animated.div className={styles.logoHolder} style={logoProps} onClick={incrementStep}>
      <img src={Logo} alt='logo' width={`${initialLogoWidth}px`} height={`${initialLogoWidth}px`}></img>
    </animated.div></div>;
}

function getScale() {
  return Math.max(window.innerWidth / 1850, window.innerHeight / 977);
}

function buildLogoTransformProps(step: number) {
  const windowWidth = window.innerWidth;
  const windowHeight = window.innerHeight;
  const scale = getScale();
  const initialLogoWidth: number = 500 * scale;
  const initialLeftValue: number = 0.5 * (windowWidth - initialLogoWidth);
  const initialTopValue: number = 0.5 * (windowHeight - initialLogoWidth);
  const targetScale = 0.38;

  const initialTop = `${initialTopValue}px`;
  const initialLeft = `${initialLeftValue}px`;

  const targetLogoWidth: number = initialLogoWidth * targetScale;
  const targetLeftValue: number = -0.7 * targetLogoWidth;
  const targetTopValue: number = targetLeftValue;

  const targetTop = `${targetTopValue}px`;
  const targetLeft = `${targetLeftValue}px`;

  const logoTransformProps = {
    opacity: step === 0 ? 1 : 0.5,
    top: step === 0 ? initialTop : targetTop,
    left: step === 0 ? initialLeft : targetLeft,
    transform: `scale(${step === 0 ? 1 : targetScale})`,
    from: {
      opacity: 1,
      top: initialTop,
      left: initialLeft,
      transform: 'scale(1)'
    }
  };

  return logoTransformProps;
}

