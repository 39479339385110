import React from 'react';
import { Home } from './home/Home';
import { Switch, Route, HashRouter } from 'react-router-dom';
import { Checkout } from './checkout/Checkout';
import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import { Login } from './login/Login';
import { ScoreBoard } from './scoreboard/ScoreBoard';
import { About } from './about/About';

const theme = createMuiTheme({
  overrides: {
    MuiStepper: {
      root: {
        backgroundColor: '#FFFFFF00'
      },
    },
  },
});

function App() {
  
  return <ThemeProvider theme={theme}>
    <HashRouter>
      <Switch>
        <Route path='/buynow'>
          <Checkout confirmation={false} />
        </Route>
        <Route path='/confirmation'>
          <Checkout confirmation={true} />
        </Route>
        <Route path='/login'>
          <Login />
        </Route>
        <Route path='/high-scores'>
          <ScoreBoard />
        </Route>
        <Route path='/about'>
          <About />
        </Route>
        <Route>
          <Home />
        </Route>
      </Switch>
    </HashRouter>
  </ThemeProvider>;
}

export default App;

