import React, { useEffect, useState } from 'react';
import { Score } from './Score';
import axios from 'axios';
import { ListScoresResponse } from './ListScoresResponse';
import styles from './ScoreBoard.module.css';
import { RingLoader } from 'react-spinners';
import { Navigation } from '../navigation/Navigation';
import { useSpring, animated } from 'react-spring';

export const SECOND_DUR_MILLIS = 1000;
export const MINUTE_DUR_MILLIS = SECOND_DUR_MILLIS * 60;
export const HOUR_DUR_MILLIS = MINUTE_DUR_MILLIS * 60;
export const DAY_DUR_MILLIS = HOUR_DUR_MILLIS * 24;

export function ScoreBoard() {
    const [scores, setScores] = useState<Array<Score>>([]);
    const [loading, setLoading] = useState(true);
    
    useEffect(() => {
        setLoading(true);
        retrieveScores()
            .then(newScores => {
                setScores(newScores);
                setLoading(false);
            });
    }, []);

    const orderedScores = scores
        .sort((a, b) => {
            if (a.finalScore > b.finalScore) {
                return 1;
            }
            else if (a.finalScore < b.finalScore) {
                return -1;
            }
            else if (a.duration > b.duration) {
                return 1;
            }
            else if (a.duration < b.duration) {
                return -1;
            }
            return 0;
        });

    const scoresFadeIn = {
        config: { duration: 1000 },
        opacity: loading ? 0 : 1,
        from: {
            opacity: 0
        }
    }

    const fadeInProps = useSpring(scoresFadeIn);

    return <><Navigation />
    <div className={styles.backgroundHolder}>
        <div className={styles.backgroundOverlay}></div>
    </div>
    
    <div className={styles.mainWrapper}>
        <div className={styles.titleWrapper}>High Scores</div>
        {
            loading && <div className={styles.loader}><RingLoader size='20vmin' color='#33AA33' /></div>
        }
        {
            !loading && <animated.div className={styles.scoresList} style={fadeInProps}>
                {
                    orderedScores.map((score, index) => {
                        return <div key={`score_${index}`} className={styles.scoreRow}>
                            <div className={styles.rank}>Position:<div className={styles.dataItem}>{index+1}</div></div>
                            <div className={styles.name}>Name:<div className={styles.dataItem}>{score.scoreboardName}</div></div>
                            <div className={styles.duration}>Duration:<div className={styles.dataItem}>{formatTime(score.duration)}</div></div>
                            <div className={styles.cluesUsed}>Clues:<div className={styles.dataItem}>{score.cluesUsed}</div></div>
                            <div className={styles.finalScore}>Final score:<div className={styles.dataItem}>{formatTime(score.finalScore)}</div></div>
                        </div>
                    })
                }
            </animated.div>
        }
    </div></>
}

function formatTime(time: number): string {
    const daysPart = getTimePart(time, DAY_DUR_MILLIS, 99);
    const hoursPart = getTimePart(time, HOUR_DUR_MILLIS, 24);
    const minutesPart = getTimePart(time, MINUTE_DUR_MILLIS, 60);
    const secondsPart = getTimePart(time, SECOND_DUR_MILLIS, 60);

    return `${daysPart}d ${hoursPart}h ${minutesPart}m ${secondsPart}s`;
}

export function getTimePart(elapsedTime: number, unit: number, maxValue: number): string {
    const value = Math.floor((elapsedTime / unit) % maxValue);

    const valueStr = `${value}`;
    if (valueStr.length === 1) {
        return `0${valueStr}`;
    }

    if (valueStr.length !== 2) {
        return '99';
    }

    return valueStr;
}

async function retrieveScores(): Promise<Array<Score>> {
    return axios.get<ListScoresResponse>('https://uj5oguggt6.execute-api.us-east-1.amazonaws.com/test/list-scores')
        .then(response => {
            if (response.status === 200) {
                const responseData = response.data;
                return responseData.scoreBoardEntries;
            }
            return [];
        })
}