import { Button, FormControl, Input, InputLabel } from '@material-ui/core';
import React, { useState } from 'react';
import { Navigation } from '../navigation/Navigation';
import styles from './Login.module.css';
import { BarLoader } from 'react-spinners';
import NoPhones from './images/no-phones.svg';

export function Login() {
    const [keyPart1, setKeyPart1] = useState('');
    const [keyPart2, setKeyPart2] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);

    const login = async () => {
        setErrorMessage('');
        setLoading(true);
        await loginWithCredentials(keyPart1, keyPart2)
            .then(err => {
                setErrorMessage(err);
                if (!err) {
                    window.location.href = 'https://www.professor-puzzler.co.uk/private/index.html';
                }
                else {
                    setLoading(false);
                }
            })
            .catch(() => {
                setErrorMessage('Something went wrong, please try again');
                setLoading(false);
            });
    };

    return <>
        <Navigation />
        <div className={styles.backgroundHolder}>
            <div className={styles.backgroundOverlay}></div>
        </div>
        <div className={styles.mainWrapper}>
            <div className={styles.verticalWrapper}>
                <div className={styles.loginPanel}>
                    <FormControl>
                        <InputLabel htmlFor="passcodePart1">Access Key Part 1</InputLabel>
                        <Input id="passcodePart1" type="text" autoComplete="off" onChange={evt => setKeyPart1(evt.target.value)} />
                    </FormControl>
                    <FormControl>
                        <InputLabel htmlFor="passcodePart2">Access Key Part 2</InputLabel>
                        <Input id="passcodePart2" type="text" autoComplete="off" onChange={evt => setKeyPart2(evt.target.value)} />
                    </FormControl>
                    <div className={styles.errorText}>{errorMessage}&nbsp;</div>
                    {
                        loading
                            ? <BarLoader width='100%' color='#33AA3322'></BarLoader>
                            : <Button
                                variant="contained"
                                color="primary"
                                onClick={login}
                            >Login</Button>
                    }
                </div>
                <div className={styles.warning}>
                    <img src={NoPhones} alt='No phones' width={24} /><div className={styles.warningText}>The following game is designed to be played on large screens</div>
                </div>
            </div>
        </div>
    </>
}

async function loginWithCredentials(keyPart1: string, keyPart2: string): Promise<string> {
    keyPart1 = (keyPart1 || '').trim();
    keyPart2 = (keyPart2 || '').trim();

    if (!keyPart1 || !keyPart2) {
        return Promise.resolve('Missing key');
    }

    const request = {
        method: 'POST',
        body: JSON.stringify({ 'username': keyPart1, 'password': keyPart2 }),
        headers: {
            'Content-Type': 'application/json'
        }
    };

    return fetch('https://uj5oguggt6.execute-api.us-east-1.amazonaws.com/test/sign-in', request)
        .then(async response => {
            if (response.status === 200) {
                const responseBody = await response.json();
                document.cookie = `token=${responseBody.token}`;
                return Promise.resolve('');
            }
            return Promise.resolve('Something went wrong, try again.');
        });
}